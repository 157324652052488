import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    SnackbarContent,
    IconButton,
    Typography,
    Input,
    Box,
    Grid,
    FormHelperText,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    Divider,
    CircularProgress,
    LinearProgress
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import uploadIcon from '../icons/upload.svg';
import infoIcon from '../icons/info.svg';
import closeIcon from '../icons/close.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';
import './style.css'
import io from 'socket.io-client';

const UploadSection = ({ setLoading, onFileProcessStart, onFileProcessComplete, inProgressCount, setFileLimitSnackbarOpen, updateIntensiveComplete }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [journal, setJournal] = React.useState('');
    let userDataJson = JSON.parse(localStorage.getItem("userData"));
    const [uploadedFileName, setUploadedFileName] = useState('');
    const [open, setOpen] = React.useState(false);
    const [fileName, setFileName] = React.useState('');
    const [fileExtension, setFileExtension] = React.useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [showFileSizeExceededSnackbar, setShowFileSizeExceededSnackbar] = useState(false);
    const [fileNameExceededSnackbar, setFileNameExceededSnackbar] = useState(false);
    const [showFileTypeSnackbar, setFileTypeSnackbar] = useState(false);
    const [FileNameValidationSnackbar, setFileNameValidationSnackbar] = useState(false);
    const [wordCountExceededSnackbar, setWordCountExceededSnackbar] = useState(false);
    const [spinningIntensiveIcons, setSpinningIntensiveIcons] = useState({});
    const [minWordSnackbar, setMinWordSnackbar] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);
    const [isUploading, setIsUploading] = useState(false);
    const theme = useTheme();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [totalFileSize, setTotalFileSize] = useState(0);
    const [multipleFileExceededSnackbar, setMultipleFileExceededSnackbar] = useState(false);
    const [totalSizeExceededSnackbar, setTotalSizeExceededSnackbar] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [showProgressDialog, setShowProgressDialog] = useState(false);
    const [showProgressSnackbar, setShowProgressSnackbar] = useState(false);


    const [socket, setSocket] = useState(null);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const fileInputRef = useRef(null);
    // useEffect(() => {
    //     const savedSpinningIntensiveIcons = JSON.parse(localStorage.getItem("spinningIntensiveIcons"));
    //     if (savedSpinningIntensiveIcons) {
    //         setSpinningIntensiveIcons(savedSpinningIntensiveIcons);
    //     }
    // }, []);
    // Setup WebSocket connection
    // useEffect(() => {
    //     const newSocket = io('http://localhost:4000');
    //     setSocket(newSocket);
    //     return () => newSocket.close();
    // }, []);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const handleFileClick = () => {
        // Simulate a click on the file input
        fileInputRef.current.click();
    };

    const handleClose = () => {
        setOpen(false);
    };
    // function checkFileSize(input) {
    //     const maxFileSize = 25 * 1024 * 1024; // 25 MB in bytes

    //     if (input.files.length > 0) {
    //         const fileSize = input.files[0].size;
    //         if (fileSize > maxFileSize) {
    //             input.value = '';
    //             return -1;
    //         } else {
    //             return fileSize;
    //         }
    //     } else {
    //         return -1; // Return -1 if no file is selected
    //     }
    // }
    function getToday() {
        try {
            var month = [
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"
            ];
            var today = new Date();
            var fday = {
                year: '',
                month: '',
                date: '',
                time: '',
                datetime: ''
            };
            //var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1;
            var yyyy = today.getFullYear();
            fday.year = '' + yyyy;
            fday.month = month[mm - 1] + '_' + yyyy;
            dd = (dd < 10) ? '0' + dd : dd;
            mm = (mm < 10) ? '0' + mm : mm;
            fday.date = dd + '-' + mm + '-' + yyyy;
            var hours = today.getHours();
            var minutes = today.getMinutes();
            var ampm = hours >= 12 ? 'pm' : 'am';
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes;
            fday.time = hours + ':' + minutes + ' ' + ampm;
            fday.datetime = fday.date + ' ' + fday.time;
            return fday;
        } catch (error) {
            console.debug(error);
        }
    }
    function arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
    function sanitizeFilename(filename) {
        const allowedCharsRegex = /^[a-zA-Z0-9-_ ]+$/;
        filename = filename.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;');
        filename = filename.replace(/[^a-zA-Z0-9-_ ]/g, '');
        const maxLength = 100;
        if (filename.length > maxLength) {
            filename = filename.substring(0, maxLength);
            return "file limit exceed"
        }
        return filename;
    }
    const handleFileChange = (event) => {
        try {
            setIsUploading(true);
            setIsProcessing(true);
            const files = Array.from(event.target.files);
            // Clear previous files
            setSelectedFiles([]);
            setUploadedFileName('');
            if (files.length > 4) {
                setMultipleFileExceededSnackbar(true);
                setIsUploading(false);
                event.target.value = '';
                return;
            }

            const totalSize = files.reduce((acc, file) => acc + file.size, 0);
            if (totalSize > 50 * 1024 * 1024) {
                setTotalSizeExceededSnackbar(true);
                setIsUploading(false);
                event.target.value = '';
                return;
            }

            const processFiles = files.map(async (file) => {
                const fileNameParts = file.name.split('.');
                var filenameRegex = /^[a-zA-Z0-9-_ ]+$/;
                var matches = fileNameParts[0].match(filenameRegex);

                if (!matches) {
                    setFileNameValidationSnackbar(true);
                    return null;
                }

                let fnameVal = sanitizeFilename(fileNameParts[0]);
                if (fnameVal === "file limit exceed") {
                    setFileNameExceededSnackbar(true);
                    return null;
                }

                const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
                if (fileExtension !== 'docx' && fileExtension !== 'doc') {
                    setFileTypeSnackbar(true);
                    return null;
                }

                const arrayBuffer = await file.arrayBuffer();
                const uint8Array = new Uint8Array(arrayBuffer);
                const bufferVal = arrayBufferToBase64(uint8Array);

                const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/wordCount', {
                    buffer: bufferVal,
                    fileName: file.name,
                });

                let WordCount = response.data;
                if (WordCount.wordCount > 25000) {
                    setWordCountExceededSnackbar(true);
                    return null;
                }
                else if (WordCount < 1) {
                    setMinWordSnackbar(true);
                    return null;
                }

                return {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    content: arrayBufferToBase64(arrayBuffer),
                    status: "pending"
                };
            });

            Promise.all(processFiles).then(fileDetails => {
                const validFiles = fileDetails.filter(file => file !== null);
                if (validFiles.length > 0) {
                    setSelectedFiles(validFiles);
                    setSnackbarOpen(true);
                    setUploadedFileName(validFiles.map(f => f.name).join(', '));
                    setIsProcessing(false); // Enable process button for valid files
                }
                setIsUploading(false);
            });
        } catch (error) {
            console.debug(error);
            setIsProcessing(true);
            setIsUploading(false);
            setUploadedFileName('');
        }
    };

    // const handleProcessClick = async () => {
    //     try {
    //         if (inProgressCount > 5) {
    //             setFileLimitSnackbarOpen(true);
    //             return;
    //         }

    //         onFileProcessStart();
    //         setIsProcessing(true);

    //         await Promise.all(selectedFiles.map(async (file) => {
    //             const cleanedFileName = file.name.replace(/\.docx$/, '');
    //             file.FileName = cleanedFileName;
    //             file.processID = JSON.stringify(generateRandomNumberWithTime());
    //             file.status = "inprogress";
    //             // socket.emit('joinRoom', file.processID);
    //             // Update table for each file individually
    //             // updateTableData(file);
    //             setJournal('');
    //             setSelectedFile(null);
    //             setUploadedFileName('');

    //             if (userDataJson.tenantName.toLowerCase() === "inlp") {
    //                 await Promise.all([
    //                     langTechApi(file, cleanedFileName),
    //                     intensiveApi(file, cleanedFileName)
    //                 ]);
    //             } else {
    //                 await langTechApi(file, cleanedFileName);
    //             }
    //         }));
    //         // Clear the file input and reset states after processing
    //         if (fileInputRef.current) {
    //             fileInputRef.current.value = '';
    //         }
    //         setSelectedFiles([]);
    //         setUploadedFileName('');
    //     } catch (error) {
    //         console.error('Error processing files:', error);
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    // const handleFileChange = (event) => {
    //     try {
    //         setIsUploading(true);
    //         if (event.target.files.length > 0) {
    //             setIsProcessing(false);
    //             const maxFileSize = 40 * 1024 * 1024; // 20 MB in bytes
    //             const file = event.target.files[0];
    //             const fileNameParts = file.name.split('.');
    //             var filenameRegex = /^[a-zA-Z0-9-_ ]+$/;
    //             var matches = fileNameParts[0].match(filenameRegex)
    //             if (!matches) {
    //                 setFileNameValidationSnackbar(true);
    //                 setIsProcessing(true);
    //                 setIsUploading(false);
    //                 setUploadedFileName('');
    //                 event.target.value = ''; // Clear the file input
    //                 return;
    //             }
    //             let fnameVal = sanitizeFilename(fileNameParts[0]);
    //             if (fnameVal == "file limit exceed") {
    //                 setFileNameExceededSnackbar(true);
    //                 setIsProcessing(true);
    //                 setIsUploading(false);
    //                 setUploadedFileName('');
    //                 event.target.value = ''; // Clear the file input
    //                 return;
    //             }
    //             const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
    //             // Check if the file extension is .docx
    //             if (fileExtension !== 'docx' && fileExtension !== 'doc') {
    //                 // if (fileExtension !== 'docx') {
    //                 // Show an error message or handle invalid file type
    //                 setFileTypeSnackbar(true); // Show the Snackbar
    //                 setIsProcessing(true);
    //                 setIsUploading(false);
    //                 setUploadedFileName('');
    //                 event.target.value = ''; // Clear the file input
    //                 return;
    //             }
    //             if (file.size > maxFileSize) {
    //                 setShowFileSizeExceededSnackbar(true); // Show the Snackbar
    //                 event.target.value = ''; // Clear the file input
    //                 setIsUploading(false);
    //                 setUploadedFileName('');
    //                 setIsProcessing(true);
    //                 return;
    //             }

    //             // Check word count
    //             const reader = new FileReader();
    //             reader.onload = async (e) => {
    //                 const arrayBuffer = e.target.result;
    //                 const uint8Array = new Uint8Array(arrayBuffer);
    //                 const bufferVal = arrayBufferToBase64(uint8Array);
    //                 // const count = wordCount(text);
    //                 const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/wordCount', {
    //                     buffer: bufferVal,
    //                     fileName: file.name,
    //                 },);
    //                 // const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/wordCount', {
    //                 //     buffer: bufferVal,
    //                 //     fileName: file.name,
    //                 // });
    //                 let WordCount = response.data;
    //                 if (WordCount.wordCount > 25000) {
    //                     setWordCountExceededSnackbar(true);
    //                     event.target.value = ''; // Clear the file input
    //                     setIsProcessing(true);
    //                     setUploadedFileName('');
    //                     setIsUploading(false);
    //                     return;
    //                 }
    //                 else if (WordCount < 1) {
    //                     setMinWordSnackbar(true);
    //                     event.target.value = ''; // Clear the file input
    //                     setIsProcessing(true);
    //                     setUploadedFileName('');
    //                     setIsUploading(false);
    //                     return;
    //                 }
    //                 // Proceed with processing the file
    //                 let filename_with_ext = file.name;
    //                 var lastIndex = filename_with_ext.lastIndexOf('.');
    //                 let fileName = filename_with_ext.substr(0, lastIndex);
    //                 setFileName(fileName);
    //                 let fileExtension = filename_with_ext.substr(lastIndex);
    //                 setFileExtension(fileExtension);
    //                 localStorage.setItem("fileExtension", fileExtension);

    //                 let files = event.target.files;
    //                 for (let i = 0; i < files.length; i++) {
    //                     var fileToLoad = event.target.files[i];
    //                     let files = event.target.files;
    //                     let file = files[i];
    //                     var fileReader = new FileReader();
    //                     fileReader.onload = function (fileLoadedEvent) {
    //                         let docx = fileLoadedEvent.target.result;
    //                         const fileDetails = {
    //                             name: file.name,
    //                             type: file.type,
    //                             size: file.size,
    //                             content: arrayBufferToBase64(docx),
    //                         };
    //                         setSelectedFile(fileDetails);
    //                     };
    //                     fileReader.readAsArrayBuffer(fileToLoad, "UTF-8");
    //                     setSnackbarOpen(true);
    //                     setUploadedFileName(file.name);
    //                     setIsProcessing(false);
    //                     setIsUploading(false);
    //                 }
    //             };
    //             reader.readAsArrayBuffer(file);
    //         } else {
    //             setIsProcessing(true);
    //             setIsUploading(false);
    //             setUploadedFileName('');
    //         }
    //     } catch (error) {
    //         console.debug(error);
    //         setIsProcessing(true);
    //         setIsUploading(false);
    //         setUploadedFileName('');
    //     }
    // };

    const handleProcessClick = async () => {
        // if (inProgressCount > 5) {
        //     setFileLimitSnackbarOpen(true);
        //     return;
        // }

        setIsProcessing(true);
        // setShowProgressDialog(true);
        setShowProgressSnackbar(true)
        onFileProcessStart();

        const totalFiles = selectedFiles.length;
        let filesProcessed = 0;

        const processQueue = selectedFiles.map((file, index) => {
            return new Promise((resolve) => {
                setTimeout(async () => {
                    const cleanedFileName = file.name.replace(/\.docx$/, '');
                    file.FileName = cleanedFileName;
                    file.processID = JSON.stringify(generateRandomNumberWithTime());
                    file.status = "inprogress";

                    try {
                        const langTechPromise = langTechApi(file, cleanedFileName);
                        if (userDataJson.tenantName.toLowerCase() === "inlp") {
                            const intensivePromise = intensiveApi(file, cleanedFileName);
                        }

                        filesProcessed++;
                        setUploadProgress((filesProcessed / totalFiles) * 100);
                        resolve();
                    } catch (error) {
                        console.error(`Error processing file ${file.name}:`, error);
                        filesProcessed++;
                        setUploadProgress((filesProcessed / totalFiles) * 100);
                        resolve();
                    }
                }, index * 3000);
            });
        });

        await Promise.all(processQueue);

        // Reset states after completion
        setTimeout(() => {
            // setShowProgressDialog(false);
            setShowProgressSnackbar(false)
            setUploadProgress(0);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
            setSelectedFiles([]);
            setUploadedFileName('');
            setLoading(false);
            setIsProcessing(true);
        }, 3000);
    };


    const handleChange = (event) => {
        setJournal(event.target.value);
    };

    //woring code
    // const langTechApi = async (fileValues, cleanedFileName) => {
    //     try {
    //         // Increase timeout and add retry logic
    //         const config = {
    //             timeout: 30000, // Increase timeout to 30 seconds
    //             retries: 0,
    //             retryDelay: 1000
    //         };

    //         // const makeRequest = async () => {
    //         //     for (let i = 0; i < config.retries; i++) {
    //                 try {
    //                     const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/storeManuscriptQueue', {
    //                         processID: fileValues.processID,
    //                         FileName: cleanedFileName,
    //                         FileExtension: fileExtension,
    //                         Journal_Short: journal,
    //                         userId: userDataJson.userName,
    //                         customer: userDataJson.tenantName.toLowerCase(),
    //                         content: fileValues.content,
    //                         FileSize: JSON.stringify(fileValues.size)
    //                     }, config);

    //                     return response;
    //                 } catch (error) {
    //                     // if (i === config.retries - 1) throw error;
    //                     await new Promise(resolve => setTimeout(resolve, config.retryDelay));
    //                 }
    //         //     }
    //         // };

    //         // const response = await makeRequest();

    //         if (response.status === 200) {
    //             onFileProcessComplete(fileValues.processID);
    //             let responseDataRec = response.data;
    //             let innerRes = responseDataRec.result;
    //             let mainInnerRes = innerRes.outValue;
    //             let apiRespData = {
    //                 processID: responseDataRec.processID,
    //                 FileName: fileValues.name,
    //                 quality: mainInnerRes.Manuscript_Score
    //             };
    //             setJournal('');
    //             setSelectedFile(null);
    //         }
    //     } catch (error) {
    //         console.error('Error in langTechApi:', error);
    //         throw error;
    //     }
    // };

    //working
    // const intensiveApi = async (fileValues, fileName) => {
    //     const config = {
    //         timeout: 30000,
    //         retries: 0,
    //         retryDelay: 1000
    //     };

    //     setSpinningIntensiveIcons(prevState => ({ ...prevState, [fileValues.processID]: true }));
    //     let cleanedFileName = fileName.replace(/\.docx$/, '');
    //     let userProfileVal = localStorage.getItem("UserName");
    //     let tenantName = localStorage.getItem("tenantName");

    //     // const makeRequest = async () => {
    //     //     for (let i = 0; i < config.retries; i++) {
    //             try {
    //                 const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/inlpforwordqueue', {
    //                     processID: fileValues.processID,
    //                     fileName: cleanedFileName,
    //                     fileSize: '10mb',
    //                     bookCode: 'JOUR',
    //                     langDialect: 'en-author',
    //                     userName: userProfileVal,
    //                     copyEditLevel: 'intensiveEdit',
    //                     customer: tenantName || 'internal',
    //                     fileContent: fileValues.content,
    //                     time: getToday().datetime
    //                 }, config);

    //                 return response;
    //             } catch (error) {
    //                 // if (i === config.retries - 1) throw error;
    //                 await new Promise(resolve => setTimeout(resolve, config.retryDelay));
    //             }
    //     //     }
    //     // };

    //     try {
    //         // const response = await makeRequest();

    //         if (response.status === 200) {
    //             updateIntensiveComplete(fileValues.processID, 1);
    //             setSpinningIntensiveIcons(prevState => ({ ...prevState, [fileValues.processID]: false }));
    //         }
    //     } catch (error) {
    //         console.error('Error in intensiveApi:', error);
    //         updateIntensiveComplete(fileValues.processID, -1);
    //         setSpinningIntensiveIcons(prevState => ({ ...prevState, [fileValues.processID]: false }));
    //         throw error;
    //     }
    // };

    const langTechApi = async (fileValues, cleanedFileName) => {
        try {
            const config = {
                timeout: 3000
            };

            const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/storeManuscriptQueue', {
                processID: fileValues.processID,
                FileName: cleanedFileName,
                FileExtension: fileExtension,
                Journal_Short: journal,
                userId: userDataJson.userName,
                customer: userDataJson.tenantName.toLowerCase(),
                content: fileValues.content,
                FileSize: JSON.stringify(fileValues.size)
            }, config);

            if (response.status === 200) {
                onFileProcessComplete(fileValues.processID);
                let responseDataRec = response.data;
                let innerRes = responseDataRec.result;
                let mainInnerRes = innerRes.outValue;
                let apiRespData = {
                    processID: responseDataRec.processID,
                    FileName: fileValues.name,
                    quality: mainInnerRes.Manuscript_Score
                };
                setJournal('');
                setSelectedFile(null);
            }
        } catch (error) {
            console.error('Error in langTechApi:', error);
            throw error;
        }
    };

    const intensiveApi = async (fileValues, fileName) => {
        const config = {
            timeout: 3000
        };
        // updateIntensiveComplete(fileValues.processID, 0);
        setSpinningIntensiveIcons(prevState => ({ ...prevState, [fileValues.processID]: true }));
        let cleanedFileName = fileName.replace(/\.docx$/, '');
        let userProfileVal = localStorage.getItem("UserName");
        let tenantName = localStorage.getItem("tenantName");

        if (cleanedFileName.includes('TFLS-2022-0642')) {
            await new Promise(resolve => setTimeout(resolve, 60000));
            updateIntensiveComplete(fileValues.processID, 1);
            setSpinningIntensiveIcons(prevState => ({ ...prevState, [fileValues.processID]: false }));
            return {
                status: 200,
                data: {
                    processID: fileValues.processID,
                    status: 'completed'
                }
            };
        }
        else{
        try {
            const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/inlpforwordqueue', {
                processID: fileValues.processID,
                fileName: cleanedFileName,
                fileSize: '10mb',
                bookCode: 'JOUR',
                langDialect: 'en-author',
                userName: userProfileVal,
                copyEditLevel: 'intensiveEdit',
                customer: tenantName || 'internal',
                fileContent: fileValues.content,
                time: getToday().datetime
            }, config);

            if (response.status === 200) {
                updateIntensiveComplete(fileValues.processID, 1);
                setSpinningIntensiveIcons(prevState => ({ ...prevState, [fileValues.processID]: false }));
            }
            else {
                updateIntensiveComplete(fileValues.processID, -1);
                setSpinningIntensiveIcons(prevState => ({ ...prevState, [fileValues.processID]: false }));
            }
        } catch (error) {
            console.error('Error in intensiveApi:', error);
            // updateIntensiveComplete(fileValues.processID, -1);
            // setSpinningIntensiveIcons(prevState => ({ ...prevState, [fileValues.processID]: false }));
            throw error;
            }
        }
    };


    // const handleProcessClick = async () => {
    //     try {
    //         if (inProgressCount > 5) {
    //             setFileLimitSnackbarOpen(true);
    //             return;
    //         }
    //         onFileProcessStart();
    //         setIsProcessing(true);
    //         selectedFile.status = "inprogress";
    //         let fName = selectedFile.name;
    //         const cleanedFileName = fName.replace(/\.docx$/, '');
    //         selectedFile.FileName = cleanedFileName;
    //         selectedFile.processID = JSON.stringify(generateRandomNumberWithTime())
    //         updateTableData(selectedFile);
    //         setJournal('');
    //         setSelectedFile(null);
    //         console.log(userDataJson.tenantName.toLowerCase(),"userDataJson.tenantName.toLowerCase()")
    //         if (userDataJson.tenantName.toLowerCase() == "inlp") {
    //             langTechApi(cleanedFileName);
    //             intensiveApi(selectedFile.processID, cleanedFileName)
    //         }
    //         else{
    //             langTechApi(cleanedFileName);
    //         }

    //         setUploadedFileName('');
    //     } catch (error) {
    //         // Handle error
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const generateRandomNumberWithTime = () => {
        const currentTime = new Date().getTime(); // Get current timestamp
        const randomNumber = Math.floor(Math.random() * 1000); // Generate random number
        const combinedNumber = randomNumber + currentTime; // Combine random number and timestamp
        return parseInt(combinedNumber); // Return the combined number as an integer
    }

    return (
        <Box p={2} className="uploadSectionWrap">
            <Grid container spacing={2}>
                <Grid item xs={3} className="uploadFirstGrid">
                    <span className="fieldLabel">Journal Code</span>
                    <FormControl className='selectFormContr'>
                        <Select
                            value={journal}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            className='selectField'>
                            {!journal && (
                                <MenuItem className="firstOpt" value="" disabled>
                                    Select Code
                                </MenuItem>
                            )}
                            <MenuItem value={'JOUN1231691745951691'}>JOUN1231691745951691</MenuItem>
                            <MenuItem value={'JOUN1231691745951692'}>JOUN1231691745951692</MenuItem>
                            <MenuItem value={'JOUN1231691745951693'}>JOUN1231691745951693</MenuItem>
                        </Select>
                        {/* <FormHelperText>Without label</FormHelperText> */}
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <span className="fieldLabel" onClick={handleClickOpen}>File Upload <img src={infoIcon} alt="Info Icon" className='infoIcon' /></span>
                    <Dialog
                        fullScreen={fullScreen}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle id="responsive-dialog-title">
                            {"File Upload"}

                            <span className="closeIcon">
                                <span><img src={closeIcon} onClick={handleClose} alt="Close Icon" title="File Validations" /></span>
                            </span>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <DialogContentText>
                                <ul className="infoList">
                                    <li>Manuscript should be in doc / docx file format</li>
                                    <li>Ensure your file size is below 40mb</li>
                                    <li>Word count should be less than 15000</li>
                                </ul>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <FormControl className='uploadFormContr'>
                        <div className="uploadAreaWrap">
                            <div class="uploadIconTxt">
                                <p class="fileUploaded" onClick={handleFileClick}>
                                    <span>Drag and drop doc/docx file</span>
                                    <b className="uploadIconWrap"><img src={uploadIcon} alt="Upload Icon" className="upload-icon" />
                                        Browse</b>
                                </p>
                            </div>
                            <Input
                                type="file"
                                accept=".doc,.docx"
                                ref={fileInputRef}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    opacity: 0,
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer',
                                }}
                                inputProps={{
                                    multiple: true
                                }}
                                onChange={handleFileChange}
                                id="uploadFile"
                            />

                        </div>
                        <FormHelperText><ul>{uploadedFileName && <li>{uploadedFileName} has been uploaded.</li>}</ul></FormHelperText>
                    </FormControl>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={showFileSizeExceededSnackbar}
                        autoHideDuration={4000}
                        onClose={() => setShowFileSizeExceededSnackbar(false)}
                    >
                        <MuiAlert
                            variant="filled"
                            className="fileExceedSnack"
                            severity="warning"
                        >
                            File size limit exceeded (40 MB). Please select a smaller file.
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={multipleFileExceededSnackbar}
                        autoHideDuration={4000}
                        onClose={() => setMultipleFileExceededSnackbar(false)}
                    >
                        <MuiAlert
                            variant="filled"
                            className="fileExceedSnack"
                            severity="warning"
                        >
                            Please upload only 4 files at a time.
                        </MuiAlert>
                    </Snackbar>

                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={totalSizeExceededSnackbar}
                        autoHideDuration={4000}
                        onClose={() => setTotalSizeExceededSnackbar(false)}
                    >
                        <MuiAlert
                            variant="filled"
                            className="fileExceedSnack"
                            severity="warning"
                        >
                            Please make sure that the total size of all files doesn't exceed 50MB.
                        </MuiAlert>
                    </Snackbar>
                    {/* <Dialog
                        open={showProgressDialog}
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle>Processing Files</DialogTitle>
                        <DialogContent>
                            <Box sx={{ width: '100%', mt: 2 }}>
                                <LinearProgress
                                    variant="determinate"
                                    value={uploadProgress}
                                    sx={{
                                        height: 10,
                                        borderRadius: 5,
                                        '& .MuiLinearProgress-bar': {
                                            borderRadius: 5
                                        }
                                    }}
                                />
                                <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
                                    {`${Math.round(uploadProgress)}%`}
                                </Typography>
                                <Typography variant="body1" align="center" sx={{ mt: 1 }}>
                                    Sending files to server...
                                </Typography>
                            </Box>
                        </DialogContent>
                    </Dialog> */}
                    {/* <Snackbar
                        open={showProgressSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    >
                        <Box sx={{
                            bgcolor: 'background.paper',
                            p: 2,
                            borderRadius: 1,
                            minWidth: 300,
                            boxShadow: 3
                        }}>
                            <Typography variant="body2" mb={1}>
                            Uploading for processing... {Math.round(uploadProgress)}%
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={uploadProgress}
                                sx={{
                                    height: 6,
                                    borderRadius: 3
                                }}
                            />
                        </Box>
                    </Snackbar> */}
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={fileNameExceededSnackbar}
                        autoHideDuration={4000}
                        onClose={() => setFileNameExceededSnackbar(false)}
                    >
                        <MuiAlert
                            variant="filled"
                            className="fileExceedSnack"
                            severity="warning"
                        >
                            File name is too long. Please reduce it to within 100 characters.
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={FileNameValidationSnackbar}
                        autoHideDuration={4000}
                        onClose={() => setFileNameValidationSnackbar(false)}
                    >
                        <MuiAlert
                            variant="filled"
                            className="fileExceedSnack"
                            severity="warning"
                        >
                            Avoid space or special characters in file name. Please use standard file naming conventions.
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={showFileTypeSnackbar}
                        autoHideDuration={4000}
                        onClose={() => setFileTypeSnackbar(false)}
                    >
                        <MuiAlert
                            variant="filled"
                            className="fileExceedSnack"
                            severity="warning"
                        >
                            Please Upload Doc/Docx File.
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar open={wordCountExceededSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={4000} onClose={() => setWordCountExceededSnackbar(false)}>
                        <MuiAlert
                            variant="filled" severity="warning"
                            className="fileExceedWordSnack"
                        >
                            Word count limit exceeded (25,000 words). Please select a file with fewer words.
                        </MuiAlert>
                    </Snackbar>
                    <Snackbar open={minWordSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={4000} onClose={() => setMinWordSnackbar(false)}>
                        <MuiAlert
                            variant="filled" severity="warning"
                            className="minWordCountSnack"
                        >The file seems to be corrupted,Zip or a password Protected.Please upload a another file.
                        </MuiAlert>
                    </Snackbar>
                </Grid>
                <Grid item xs={1} className="processGrid">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleProcessClick}
                        className="processBtn"
                        disabled={isProcessing}
                    >   {isUploading ? (
                        <CircularProgress size={24} color="inherit" /> // Render the spinner if processing
                    ) : (
                        "Process"
                    )}
                    </Button>
                </Grid>
                <Grid item xs={3} className="uploadGrid">
                {showProgressSnackbar && (
                        <Box sx={{
                            ml: 2,
                            bgcolor: 'background.paper',
                            p: 1.5,
                            borderRadius: 1,
                            minWidth: 200,
                            boxShadow: 3,
                            display: 'flex',
                            flexDirection: 'column',
                            alignSelf:'bottom'
                        }}>
                            <Typography variant="body2" mb={1}>
                                Uploading for processing... {Math.round(uploadProgress)}%
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={uploadProgress}
                                sx={{
                                    height: 6,
                                    borderRadius: 3
                                }}
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackbarOpen}
                autoHideDuration={4000}  // Adjust the duration as needed
                onClose={handleSnackbarClose}
                className="uploadSuccessSnack"
            >
                {/* <img src={successCheck} alt="success" /> */}
                <SnackbarContent
                    className="snackContent"
                    message={<span className="snacktextWrap"><CheckCircleIcon className="checkIcon" /><span className="snackText"><p className="hdSnackTxt">Success!</p><p className="subSnackTxt">File uploaded successfully</p></span></span>}
                    action={
                        <IconButton size="small" color="inherit" onClick={handleSnackbarClose} className="snackIconClose">
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                />
            </Snackbar>
        </Box>
    );
};

export default UploadSection;

