// ** React Imports
import React, { useState, useContext } from 'react'

// ** Router Imports
import { useNavigate } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import ResponsiveDialog from './logoutConfirmation'
import integraLogo from "../icons/authorPilot_logo.png";
// import { ToastContainer } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css';

// Dummy data and functions to replace local imports
const SettingsContext = React.createContext({
  direction: 'rtl'
});

const AuthContext = React.createContext({
  user: {
    firstName: 'John',
    lastName: 'Doe'
  },
  logout: () => console.log('Logged out')
});

const ThemeConfigContext = React.createContext({
  currentThemeConfig: {
    primaryBgColor: '#3f51b5',
    primaryTextColor: '#ffffff'
  }
});



// ** Styled Components
const BadgeContentSpan = styled('span')(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}));

const UserDropdown = (props) => {
  // ** States
  const [anchorEl, setAnchorEl] = useState(null)
  const [openDeletionBox, setopenDeletionBox] = useState(false)
  const cancelDeletionBox = () => setopenDeletionBox(false)

  // ** Hooks
  const navigate = useNavigate()
  const { logout } = useAuth0() // using useAuth0 directly here

  // ** Contexts
  const settingsContext = useContext(SettingsContext);
  const authContext = useContext(AuthContext);
  const themeConfigContext = useContext(ThemeConfigContext);

  // ** Vars
  const { direction } = settingsContext
//   const loginUser = authContext.user
  const { currentThemeConfig } = themeConfigContext

  const loginUser=JSON.parse(localStorage.getItem("userData"));
  const userInitials = `${(loginUser?.firstName?.charAt(0) || '').toUpperCase()}${(loginUser?.lastName?.charAt(0) || '').toUpperCase()}`;
  const handleDropdownOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDropdownClose = (url) => {
    if (url) {
      navigate(url)
    }
    setAnchorEl(null)
  }

  const handleLogout = () => {
    setopenDeletionBox(true)
    handleDropdownClose()
    // logout()
  }
  const ConfirmLogout = () => {
    setopenDeletionBox(false)
    logout()
  }
  const handleProfile = () => {
    // navigate(profile);
    handleDropdownClose()
  }

  return (
    <>
      <>
        <Badge
          overlap='circular'
          onClick={handleDropdownOpen}
          sx={{ ml: 2, cursor: 'pointer' }}
          badgeContent={<BadgeContentSpan />}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          className='userProfileDropWrap'
        >
          <div className="avatar-container">
            <Avatar
              alt={loginUser?.firstName}
              onClick={handleDropdownOpen}
              className="avatar-svg"
              style={{ backgroundColor: currentThemeConfig.primaryBgColor, color: currentThemeConfig.primaryTextColor }}
            >
              <svg width="40" height="40" viewBox="0 0 40 40">
                <rect width="40" height="40" rx="20" fill="transparent" />
                <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" className="avatar-text" style={{ color: currentThemeConfig.primaryTextColor, fill: currentThemeConfig.primaryTextColor }}>
                  {userInitials}
                </text>
              </svg>
            </Avatar>
          </div>
        </Badge>
        <div className="mainLogoClass">
          <img src={integraLogo} className="mainLogo" alt="IntegraLogo" />
        </div>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => handleDropdownClose()}
          sx={{ '& .MuiMenu-paper': { width: 180, borderRadius: '8px', overflow: 'hidden' } }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: direction === 'ltr' ? 'right' : 'left'
          }}
          className='Menu_profile_dropdown'
        >
          {/* <MenuItem onClick={handleProfile} className='Profile_menuItem'>
            View Profile
          </MenuItem> */}
          <MenuItem onClick={handleLogout} className='Logout_menuItem'>
            Log Out
          </MenuItem>
        </Menu>
        <ResponsiveDialog open={openDeletionBox} confirm={ConfirmLogout} cancel={cancelDeletionBox} />
      </>
      {/* <ToastContainer position="top-center" autoClose={3000} /> */}
    </>
  )
}

// Wrap App component in context providers
// const App = () => (
//   <SettingsContext.Provider value={{ direction: 'rtl' }}>
//     <AuthContext.Provider value={{ user: { firstName: loginUser.firstName, lastName: loginUser.lastName }, logout: () => console.log('Logged out') }}>
//       <ThemeConfigContext.Provider value={{ currentThemeConfig: { primaryBgColor: '#3f51b5', primaryTextColor: '#ffffff' } }}>
//         <UserDropdown />
//       </ThemeConfigContext.Provider>
//     </AuthContext.Provider>
//   </SettingsContext.Provider>
// );

export default UserDropdown;
