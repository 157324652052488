import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import UploadSection from './UploadSection';
import TableSection from './TableSection';
import { useAuth0 } from '@auth0/auth0-react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useLocation } from 'react-router-dom';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function ManuscriptReadiness() {
    const [loading, setLoading] = useState(false);
    const [intensiveCompleteMap, setIntensiveCompleteMap] = useState({});
    const [mlaCompleteMap, setMlaCompleteMap] = useState({});
    const [tarCompleteMap, setTarCompleteMap] = useState({});
    const [dbRespData, setDbRespData] = useState(null);

    let userDataJson = JSON.parse(localStorage.getItem("userData"));
    const username = userDataJson?.userName || 'defaultUser';
    let tenantName = userDataJson?.tenantName || 'inlp';
    const tenantCheckVal=tenantName.toLowerCase()

    const [inProgressCount, setInProgressCount] = useState(0);
    const [fileLimitSnackbarOpen, setFileLimitSnackbarOpen] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const intervalRef = useRef(null);

    const removeContentField = (data) => {
        return data.map(item => {
            const { content, ...rest } = item; // Destructure and exclude the 'content' field
            return rest;
        });
    };

    const updateIntensiveComplete = (processID, newIntensiveComplete) => {
        setIntensiveCompleteMap((prevMap) => ({
            ...prevMap,
            [processID]: newIntensiveComplete,
        }));
    };

    const updateMlaComplete = (processID, newMlaComplete) => {
        setMlaCompleteMap((prevMap) => ({
            ...prevMap,
            [processID]: newMlaComplete,
        }));
    };

    const updateTarComplete = (processID, newTarComplete) => {
        setTarCompleteMap((prevMap) => ({
            ...prevMap,
            [processID]: newTarComplete,
        }));
    };

    const handleFileProcessStart = () => {
        setInProgressCount(prevCount => prevCount + 1);
    };

    const handleFileProcessComplete = () => {
        setInProgressCount(prevCount => prevCount - 1);
    };

    const handleSnackbarClose = () => {
        setFileLimitSnackbarOpen(false);
    };

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const response = await axios.post('https://inlp-tandf-pro-api.azurewebsites.net/db/getTandFData', {
                    userId: userDataJson.userName,
                });

                if (isMounted && response.data) {
                    setDbRespData(response.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        // Initial fetch
        fetchData();

        // Set up polling interval
        intervalRef.current = setInterval(fetchData, 3000);

        return () => {
            isMounted = false;
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [userDataJson.userName]);

    return (
        <div className="mrWrap">
            <h2>{tenantCheckVal == "inlptenant" ? ("JEO Checks") : tenantCheckVal == "postacceptance" ? ("Post-Acceptance Readiness") : ("Pre-Submission Readiness")}</h2>
            <UploadSection
                // updateTableData={updateTableData}
                setLoading={setLoading}
                onFileProcessStart={handleFileProcessStart}
                inProgressCount={inProgressCount}
                onFileProcessComplete={handleFileProcessComplete}
                setFileLimitSnackbarOpen={setFileLimitSnackbarOpen}
                updateIntensiveComplete={updateIntensiveComplete}
            />
            {dbRespData && <TableSection
                loading={loading}
                dbRespData={dbRespData}
                updateIntensiveComplete={updateIntensiveComplete}
                intensiveCompleteMap={intensiveCompleteMap}
                updateMlaComplete={updateMlaComplete}
                mlaCompleteMap={mlaCompleteMap}
                updateTarComplete={updateTarComplete}
                tarCompleteMap={tarCompleteMap}
            />}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={fileLimitSnackbarOpen}
                autoHideDuration={4000}
                onClose={handleSnackbarClose}
            >
                <MuiAlert
                    variant="filled"
                    className="fileExceedSnack"
                    severity="warning"
                >
                    Maximum number of articles being processed. Please wait...
                </MuiAlert>
            </Snackbar>
        </div>
    );
}
